import React from "react"

import * as edaStyles from "./link-btn.module.scss"

const LinkBtn = ({ url, msg = "Try this code in EDA Playground" }) => {
  return (
    <a
      className={edaStyles.edaBtn}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {msg}
      <span className={edaStyles.icon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18px"
          viewBox="0 0 24 24"
          width="18px"
          fill="rgb(8,8,8)"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V3h-6c-.55 0-1 .45-1 1z" />
        </svg>
      </span>

      {/* <span>launch</span> */}
    </a>
  )
}

export default LinkBtn
