// expression to recognize \$
const expr = new RegExp(`\\\\\\$`, `g`)

// expression to recognize "// L" comments
const expr2 = new RegExp(`// L[0-9].*`, `g`)

// cleans up the code and send it back
const codeCleanup = code => {
  code = code.replace(expr, "$")
  //   console.log(code)

  //   console.log(expr2)
  //   var temp = code.match(expr2)
  var temp = code.replace(expr2, ``)
  //   console.log(temp)
  return temp
}
export default codeCleanup
