import React from "react"

const OutputBox = ({ children }) => {
  return (
    <pre
      className="grvsc-container"
      style={{
        backgroundColor: "white",
      }}
    >
      <code
        style={{
          //   paddingRight: "1rem",
          width: "fit-content",
          color: "rgb(26 70 37)",
          paddingInline: "1.5rem",
        }}
      >
        {children}
      </code>
    </pre>
  )
}

export default OutputBox
