import React from "react"

import { svgImg, img } from "./svg-img.module.scss"

const SvgImg = ({ src, alt, height, children }) => {
  return (
    <figure className={svgImg}>
      <img
        src={src}
        alt={alt}
        className={img}
        style={height ? `max-height:${height}` : ``}
      />
      {children && <figcaption>{children}</figcaption>}
    </figure>
  )
}

export default SvgImg
