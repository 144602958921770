import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
// import icon from "../images/gatsby-icon.png"
import icon from "../images/gatsby-icon2.png"

import { graphql, useStaticQuery } from "gatsby"

const SEO = ({ title, description, image, article, structData }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle
          defaultDescription
          siteUrl
          defaultImage
        }
      }
    }
  `)

  const {
    defaultTitle,
    // titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    // twitterUsername,
  } = data.site.siteMetadata

  const seo = {
    title: title === null ? `${defaultTitle}` : `${title} | ${defaultTitle}`,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <link rel="icon" href={icon} type="image/png" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* OG tags for facebook, linkedin, whatsapp, etc */}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}

      {/* Twitter tags for sharing in twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="theoctetinstitute.com" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.url && <meta property="twitter:url" content={seo.url} />}
      {structData && <script type="application/ld+json">{structData}</script>}
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  structData: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  structData: null,
}
