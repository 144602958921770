import React from "react"
import { MDXProvider } from "@mdx-js/react"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

import Footer from "./footer"
import Header from "./header"
import CopyButton from "./copy-button"
import ActionBtn from "./action-btn"
import LinkBtn from "./link-btn"
import SvgImg from "./svg-img"
import Quote from "./quote"
import OutputBox from "./output-box"

import "../styles/index.scss"
import "../styles/mystyles.scss"
import "../styles/_variables.scss"
import * as layoutStyles from "./layout.module.scss"

const Layout = props => {
  const state = {
    precontent: CopyButton,
    LinkBtn,
    Link,
    SvgImg,
    Quote,
    OutputBox,
  }
  return (
    <div className={props.isHome ? null : layoutStyles.container}>
      <Header />
      {/* <main> */}
      {props.isHome ? (
        props.children
      ) : (
        <MDXProvider components={state}>
          <div className={layoutStyles.content}>{props.children}</div>
        </MDXProvider>
      )}
      {/* </main> */}

      <ActionBtn />

      <Footer />
      <CookieConsent
        // debug={true}
        // disableStyles={true}
        location="bottom"
        buttonText="I understand"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          background: "#2B373BF9",
          fontFamily: "Roboto",
          fontSize: "0.8rem",
          alignItems: "center",
        }}
        buttonStyle={{
          background: "rgb(101, 206, 230)",
          color: "#222",
          fontSize: "13px",
          borderRadius: "6px",
          marginTop: "-0.5rem",
        }}
        buttonClasses="button is-primary"
        expires={150}
        containerClasses={layoutStyles.cookieContainer}
        // enableDeclineButton
        // flipButtons
        // overlay
      >
        By using our site you agree to our use of cookies to deliver a better
        site experience.
      </CookieConsent>
    </div>
  )
}

export default Layout
