import React, { useState } from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import * as HeaderStyles from "./header.module.scss"
import logo from "../images/wide_logo.svg"

const Header = () => {
  const [isActive, setActive] = useState(false)
  //   console.log("Test", `${HeaderStyles.toggle} ${HeaderStyles.active}`)
  return (
    <header>
      <div className={HeaderStyles.bg}></div>
      <div
        role="button"
        tabIndex="0"
        aria-label={isActive ? "Close Menu" : "Open Menu"}
        className={
          isActive
            ? `${HeaderStyles.toggle} ${HeaderStyles.active}`
            : HeaderStyles.toggle
        }
        onClick={() => setActive(!isActive)}
        onKeyDown={e => {
          if (e.key === "Enter") setActive(!isActive)
        }}
      ></div>
      <div
        role="none"
        className={isActive ? HeaderStyles.closeArea : null}
        onClick={() => setActive(!isActive)}
      ></div>
      <Link className={HeaderStyles.imageWrapper} to="/">
        {/* <StaticImage
          src="../images/wideLogo.jpg"
          alt="Logo"
          loading="eager"
          //   className={HeaderStyles.imageWrapper}
        /> */}
        <img
          className={HeaderStyles.logo}
          src={logo}
          alt="Logo"
          height="40px"
        />
      </Link>
      {/* <img src="assets/logo.png" height="70px" /> */}
      <nav className={isActive ? HeaderStyles.active : null}>
        <ul className={HeaderStyles.navList}>
          <li>
            <Link to="/" activeClassName={HeaderStyles.navActive}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/content/"
              activeClassName={HeaderStyles.navActive}
              partiallyActive={true}
            >
              Content
            </Link>
          </li>
          <li>
            <Link to="/about/" activeClassName={HeaderStyles.navActive}>
              About
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
