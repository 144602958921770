import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/mystyles.scss"
import * as footerStyles from "./footer.module.scss"
const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.info}>
        <p>
          ©️2021-2024, <b>{data.site.siteMetadata.author}</b>
        </p>
        <p>All rights reserved</p>
      </div>
      <div className={footerStyles.appBadgeContainer}>
        <p>Download our app for easy access on go</p>
        <a
          href="https://play.google.com/store/apps/details?id=com.theoctinst.theoct&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
