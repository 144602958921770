import React, { useState, useEffect } from "react"

import * as styles from "./action-btn.module.scss"

function scrollToTop(smooth = false) {
  if (smooth) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  } else {
    document.documentElement.scrollTop = 0
  }
}

const ActionBtn = ({ top = 30, smooth = true }) => {
  const [visible, setVisible] = useState(false)
  const onScroll = () => {
    setVisible(document.documentElement.scrollTop > top)
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll)
    // Remove listener on unmount
    return () => document.removeEventListener("scroll", onScroll)
  }, [])

  return (
    <>
      <button
        className={
          visible ? `${styles.actnBtn} ${styles.active}` : styles.actnBtn
        }
        onClick={() => scrollToTop(smooth)}
        aria-label="go to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="36px"
          viewBox="0 0 24 24"
          width="36px"
          fill="#000d"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z" />
        </svg>
      </button>
    </>
  )
}

export default ActionBtn
