import React, { useState } from "react"
import normalize from "../utils/normalize"
import codeCleanup from "../utils/code-cleanup"

import * as styles from "./copy-button.module.scss"

const copyToClipboard = str => {
  const el = document.createElement("textarea")
  el.value = str
  el.setAttribute("readonly", "")
  el.style.position = "absolute"
  el.style.left = "-9999px"
  document.body.appendChild(el)
  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
}

const CopyButton = ({
  className,
  content, // raw content passed by gatsby-remark-pre-content
  duration = 5000,
  trim = false,
}) => {
  //   console.log(content)
  const [copied, setCopied] = useState(false)
  const [stripped] = normalize(content) // strip unwanted comments
  //   const [stripped] = content // strip unwanted comments
  //   console.log(stripped)
  const cleanedCode = codeCleanup(stripped)
  const label = copied ? `copied to clipboard` : `copy code to clipboard`
  return (
    <div className={styles.container}>
      <button
        type="button"
        title={label}
        name={label}
        className={styles.copyButton}
        disabled={copied}
        onClick={() => {
          copyToClipboard(cleanedCode)
          setCopied(true)
          setTimeout(() => setCopied(false), 3000)
        }}
      >
        {copied ? "🎉 Copied!" : "Copy"}
        <span className={styles.ariaStatus} aria-roledescription="status">
          {label}
        </span>
      </button>
    </div>
  )
}
export default CopyButton
